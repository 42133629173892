import { useState, useEffect, useMemo, useRef } from "preact/compat";

import Modal from "@components/Modal";
import Selected from "@components/Selected";
import { SearchInput } from "@components/SearchInput";
import { getGeo, type CountriesWithDeliveryEstimationsType } from "@lib/tangem";

import "./DeliveryInfo.scss";
import useModal from "src/hooks/useModal";
import { useFocusVisible } from "src/hooks/useFocusElement";
import {
  activeCountry,
  countryInputText,
  isSoldOut,
  activeSku,
  isProductInPreOrderWarehouse,
  activeCountryCode,
} from "../model";
import { useComputed, useSignalEffect } from "@preact/signals";
import {
  preOrderByCountriesSkus,
  type SkuType,
} from "../../../data/tangem-wallets.ts";
import { FlagIcon } from "@components/SvgIcon";

type Props = {
  textEstimation: string;
  textBusinessDays: string;
  labelSearchText: string;
  labelPlaceholderText: string;
  countriesWithEstimations: CountriesWithDeliveryEstimationsType[];
  noResultsFoundText: string;
  soldOutShippingText: string;
  chooseCountryText: string;
  shippingStarts?: Record<SkuType, string>;
};

export const DeliveryInfo = ({
  textEstimation,
  textBusinessDays,
  labelSearchText,
  labelPlaceholderText,
  countriesWithEstimations,
  noResultsFoundText,
  soldOutShippingText,
  chooseCountryText,
  shippingStarts,
}: Props) => {
  const sortedCountries = useMemo(
    () => countriesWithEstimations.sort((a, b) => a.name.localeCompare(b.name)),
    [countriesWithEstimations],
  );

  const [countryList, setCountryList] = useState(sortedCountries);
  const [daysFrom, setDaysFrom] = useState<number | null>(null);
  const [daysTo, setDaysTo] = useState<number | null>(null);
  const scrollableListElement = useRef<HTMLUListElement | null>(null);

  const { isShowing, hide, show } = useModal("search", "");
  const searchInputRef = useFocusVisible<HTMLInputElement>([isShowing]);

  useEffect(() => {
    scrollableListElement.current?.scrollTo({ top: 0, behavior: "instant" });
  }, [isShowing]);

  useEffect(() => {
    getGeo().then(({ code }) => {
      const countryByGeo = countryList.find((country) => country.code === code);

      if (countryByGeo) {
        activeCountry.value = { code, name: countryByGeo.name };
        const { from, to } = countriesWithEstimations.find(
          (country) => country.code === code,
        )!;
        setDaysFrom(from);
        setDaysTo(to);
      }
    });
  }, []);

  useSignalEffect(() => {
    const countryCode = activeCountry.value?.code;
    if (!countryCode) return;
    const { from, to } = countriesWithEstimations.find(
      (country) => country.code === countryCode,
    )!;
    setDaysFrom(from);
    setDaysTo(to);
  });

  function handleCountrySelect(code: string, name: string) {
    activeCountry.value = { code, name };
    handleModalClose();
  }

  const handleCountryChange = (inputText: string) => {
    const value = inputText.toLowerCase().trim();
    if (value === "") {
      // to prevent flickering of list and clearing input delay
      setTimeout(() => {
        setCountryList(sortedCountries);
      });
      return;
    }
    const filtered = sortedCountries
      .filter(({ name }) => name.toLowerCase().includes(value))
      .sort(
        (a, b) =>
          a.name.toLowerCase().indexOf(value) -
          b.name.toLowerCase().indexOf(value),
      );
    setCountryList(filtered.slice(0, 5));
  };

  const handleModalClose = () => {
    hide();
    setCountryList(sortedCountries);
    countryInputText.value = "";
  };

  const activeCountryName = useComputed(
    () => activeCountry?.value?.name ?? chooseCountryText,
  );

  const activeCountryListItem = useComputed(() => {
    activeCountry.value && (
      <li key={activeCountry.value.code}>
        <button
          className="country-list__item country-list__item_selected"
          onClick={handleModalClose}
        >
          <FlagIcon
            countryCode={activeCountry.value.code}
            className="country-list__img"
          />
          <span className="country-list__text">{activeCountry.value.name}</span>
        </button>
      </li>
    );
  });

  const delayedShippingStartsText = useComputed(() => {
    if (!activeSku.value) return null;
    const shippingStartsUntyped: Record<string, string> | undefined =
      shippingStarts;
    return (
      ((isProductInPreOrderWarehouse.value ||
        !preOrderByCountriesSkus[activeSku.value]) &&
        shippingStartsUntyped?.[activeSku.value]) ||
      null
    );
  });

  const soldOutShippingTextComputed = useComputed(
    () => (isSoldOut.value && soldOutShippingText) || null,
  );

  return (
    <>
      <p
        class="delivery__text"
        style={!soldOutShippingTextComputed.value ? { display: "none" } : {}}
      >
        <span>{soldOutShippingTextComputed}</span>
      </p>
      <p
        class="delivery__text"
        style={!delayedShippingStartsText.value ? { display: "none" } : {}}
      >
        <span class="delivery__icon">{delayedShippingStartsText}</span>
      </p>
      <p
        class="delivery__text"
        style={
          delayedShippingStartsText.value || soldOutShippingTextComputed.value
            ? { display: "none" }
            : {}
        }
      >
        <span class="delivery__icon">{textEstimation}:</span>
        {daysFrom ? (
          <>
            <span className="text_white">
              {" "}
              {daysFrom}-{daysTo}{" "}
            </span>

            <Selected text={textBusinessDays} selectedClass="text_white" />
          </>
        ) : null}
        &nbsp;
        <button
          type="button"
          onClick={show}
          class="text_white text_underline-dashed delivery__button"
        >
          {activeCountryName}
        </button>
      </p>
      <Modal isShowing={isShowing} hide={handleModalClose} isCentered>
        <div className="popup__container">
          <button
            className="popup__close-button"
            onClick={handleModalClose}
            aria-label="Close modal"
          ></button>
          <div className="search__wrapper ">
            <SearchInput
              model={countryInputText}
              labelText={labelSearchText}
              placeholdertext={labelPlaceholderText}
              onSearchChange={handleCountryChange}
              onReset={() => setCountryList(sortedCountries)}
              id="country"
              className="search__container"
              searchRef={searchInputRef}
            />
          </div>
          {countryList.length > 0 ? (
            <div className="country-list__wrapper">
              <ul className="country-list__list" ref={scrollableListElement}>
                {activeCountryListItem}
                {countryList.map(({ code, name }) =>
                  code === activeCountryCode.value ? null : (
                    <li key={code}>
                      <button
                        className="country-list__item"
                        onClick={() => handleCountrySelect(code, name)}
                      >
                        <FlagIcon
                          countryCode={code}
                          className="country-list__img"
                        />
                        <span className="country-list__text">{name}</span>
                      </button>
                    </li>
                  ),
                )}
              </ul>
            </div>
          ) : (
            <span className="country-list__empty">{noResultsFoundText}</span>
          )}
        </div>
      </Modal>
    </>
  );
};
